import colors from "../../../Constants/colors";

export const DeployedBotsContainerStyles = {
  display: "flex",
  bgcolor: colors.extremeBlue,
  flexDirection: "column",
  padding: "20px",
  width: "100%",
  height: "100vh",
  color: colors.extremeWhite,
};

export const DeployedBotsPaperStyles = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: colors.extremeBlue,
  my: "40px",
  boxShadow: `0px 0px 70px ${colors.secondaryBlue}`,
  padding: 6,
  overflowY: "hidden",
  height: "90vh",
  WebkitOverflowScrolling: "touch",
  scrollbarWidth: "thin",
  scrollbarColor: colors.secondaryBlue,
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.darkGray,
  },
};
export const DeployedBotsTableContainerStyles = {
  overflowY: "auto",
  width: "100%",
  "&::-webkit-scrollbar": {
    width: "12px",
    height: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    display: "none",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: colors.primaryBlue,
    borderRadius: "10px",
  },
  scrollbarWidth: "thin",
  scrollbarColor: `${colors.primaryBlue} ${colors.secondaryBlue}`,
  backgroundColor: colors.extremeBlue,
  // boxShadow: `0px 0px 80px ${colors.secondaryBlue}`,
  marginTop: "20px",
  // paddingBottom: "20px",
  overflowX: "auto",
  borderRadius: 2,
};

export const tableCellStyleHeader = {
  color: colors.extremeWhite,
  fontWeight: "bold",
  fontSize: "1rem",
  bgcolor: colors.primaryBlue,
  whiteSpace: "nowrap", // Prevents text wrapping for long headers
  textOverflow: "ellipsis", // Ensures long text is truncated with an ellipsis
  overflow: "hidden", // Prevents overflow of content in small screens
  padding: "8px",
  padding: "20px 24px",
};

export const tableCellStyleBody = {
  bgcolor: colors.darkGray,
  fontSize: "0.875rem",
  color: colors.extremeWhite,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  padding: "8px",
  minWidth: "80px",
  padding: "20px 24px",
};

export const textfieldLayout = {
  backgroundColor: colors.textFieldBackground,
  color: colors.extremeWhite,
  mr: 2,
  borderRadius: "25px", // Increase the border-radius for a rounder look
  borderColor: colors.tertiaryBlue,
  "& .MuiOutlinedInput-root": {
    padding: "1px 14px", // Adjust padding to reduce height
    fontSize: "0.875rem", // Adjust font size
    borderRadius: "25px", // Rounder corners inside the TextField
    "& fieldset": {
      borderColor: colors.tertiaryBlue,
    },
    "&:hover fieldset": {
      borderColor: colors.primaryBlue,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.primaryBlue,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.darkGray,
      color: colors.lightGray,
    },
  },
  input: {
    color: colors.extremeWhite,
    "&.Mui-disabled": {
      color: colors.lightGray,
    },
  },
};

export const ChatBoxMessagesStyles = {
  flex: 3,
  overflowY: "auto",
  mt: 1,
  width: "100%",
  WebkitOverflowScrolling: "touch", // For smooth scrolling on iOS
  scrollbarWidth: "thin", // For Firefox
  scrollbarColor: `${colors.secondaryBlue} ${colors.extremeBlack}`, // Custom scrollbar thumb and track colors
  "&::-webkit-scrollbar": {
    width: "8px", // Custom scrollbar width
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.secondaryBlue, // Custom scrollbar thumb color
    borderRadius: "10px", // Rounding scrollbar edges
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: colors.extremeBlack, // Track background color
    borderRadius: "10px", // Round track edges for consistency
  },
  padding: 2,
};

export const HeaderButtonStyles = {
  color: colors.extremeWhite,
  bgcolor: colors.primaryBlue,
  ":hover": { bgcolor: colors.secondaryBlue },
  textTransform: "none !important",
  "&.Mui-disabled": {
    color: colors.lightGray,
    bgcolor: colors.secondaryBlue,
  },
};
