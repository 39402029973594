import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chatbotService from "./chatbotService";
//get user from local storage
// const chatbots = JSON.parse(localStorage.getItem("chatbots"));
const token = JSON.parse(localStorage.getItem("token"));
// const combinations = JSON.parse(localStorage.getItem("combinations"));
// const generation_combinations = JSON.parse(localStorage.getItem("generation_combinations"));
// const finetuning_combinations = JSON.parse(localStorage.getItem("finetuning_combinations"));
const defaultCombination = JSON.parse(
  localStorage.getItem("defaultCombination")
);
const finalCombinations = JSON.parse(localStorage.getItem("finalCombinations"));
const prompts = JSON.parse(localStorage.getItem("prompts"));

const initialState = {
  chatbots: [],
  token: token ? token : null,
  combinations: [],
  generation_combinations: [],
  finetuning_combinations: [],
  prompts: prompts ? prompts : [],
  selected_combinations: [],
  combinationUsed: null,
  dataTypes: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  gettingCombinations: false,
  message: "",
  botData: {},
  defaultCombination: defaultCombination ? defaultCombination : {},
  finalCombinations: finalCombinations ? finalCombinations : [],
  isFinetuning: false,
};
//upload files

export const createBot = createAsyncThunk(
  "chatbot/createBot",
  async (botData, thunkAPI) => {
    try {
      return await chatbotService.createBot(botData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get bot by org
export const getBotbyOrg = createAsyncThunk(
  "chatbot/getBotbyOrg",
  async (id, thunkAPI) => {
    try {
      return await chatbotService.getBotbyOrg(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get combinations
export const getCombinations = createAsyncThunk(
  "chatbot/getCombinations",
  async (id, thunkAPI) => {
    try {
      return await chatbotService.getCombinations(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get generation combinations
export const getgenerationCombinations = createAsyncThunk(
  "chatbot/getgenerationCombinations",
  async (id, thunkAPI) => {
    try {
      return await chatbotService.getgenerationCombinations(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get generation combinations
export const getFinetuningCombinations = createAsyncThunk(
  "chatbot/getFinetuningCombinations",
  async (id, thunkAPI) => {
    try {
      return await chatbotService.getFinetuningCombinations(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//run evals
export const runEvals = createAsyncThunk(
  "chatbot/runEvals",
  async (ids, thunkAPI) => {
    try {
      return await chatbotService.runEvals(ids, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//run evals
export const runFinetuningEvals = createAsyncThunk(
  "chatbot/runFinetuningEvals",
  async (ids, thunkAPI) => {
    try {
      return await chatbotService.runFinetuningEvals(ids, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//run generation evals
export const runGenerationEvals = createAsyncThunk(
  "chatbot/runGenerationEvals",
  async (ids, thunkAPI) => {
    try {
      return await chatbotService.runGenerationEvals(ids, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//start finetuning
export const startFinetuning = createAsyncThunk(
  "chatbot/startFinetuning",
  async (ids, thunkAPI) => {
    try {
      return await chatbotService.startFinetuning(ids, token);
    } catch (error) {
      const status = error.response ? error.response.status : null;
      const message =
        (error.response && error.response.data && error.response.data.status) || // Using the 'status' field from the response data
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({ message, status });
    }
  }
);

//start finetuning
export const getFinetuningStatus = createAsyncThunk(
  "chatbot/getFinetuningStatus",
  async (ids, thunkAPI) => {
    try {
      return await chatbotService.getFinetuningStatus(ids, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//generate Prompts
export const generatePrompts = createAsyncThunk(
  "chatbot/generatePrompts",
  async (bot_id, thunkAPI) => {
    try {
      return await chatbotService.generatePrompts(bot_id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//generate Prompts
export const generateQueryWithProblemStatement = createAsyncThunk(
  "chatbot/generateQueryWithProblemStatement",
  async (bot_id, thunkAPI) => {
    try {
      return await chatbotService.generateQueryWithProblemStatement(
        bot_id,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//initialize Combinations
export const initializeCombinations = createAsyncThunk(
  "chatbot/initializeCombinations",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.initializeCombinations(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//initialize Combinations
export const initializeEmbeddingFinetuning = createAsyncThunk(
  "chatbot/initializeEmbeddingFinetuning",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.initializeEmbeddingFinetuning(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//initialize mutations
export const initializeMutations = createAsyncThunk(
  "chatbot/initializeMutations",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.initializeMutations(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get selected combinations
export const get_selected_combinations = createAsyncThunk(
  "chatbot/getSelectedCombinations",
  async (id, thunkAPI) => {
    try {
      return await chatbotService.get_selected_combinations(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get selected combinations
export const makeDefault = createAsyncThunk(
  "chatbot/makeDefault",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.makeDefault(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete chatbot
export const deleteChatbot = createAsyncThunk(
  "chatbot/deleteChatbot",
  async (id, thunkAPI) => {
    try {
      return await chatbotService.deleteChatbot(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Upload sources
export const uploadSources = createAsyncThunk(
  "chatbot/uploadSources",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.uploadSources(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Upload sources
export const getDataTypes = createAsyncThunk(
  "chatbot/getDataTypes",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.getDataTypes(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Upload sources
export const getMetadataDescription = createAsyncThunk(
  "chatbot/getMetadataDescription",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.getMetadataDescription(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get defualt combinations
export const getDefaultCombinations = createAsyncThunk(
  "chatbot/getDefaultCombinations",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.getDefaultCombinations(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//save default combinations
export const saveDefaultCombinations = createAsyncThunk(
  "chatbot/saveDefaultCombinations",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.saveDefaultCombinations(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get Finetuning Training Loss
export const getFinetuningTrainingLoss = createAsyncThunk(
  "chatbot/getFinetuningTrainingLoss",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.getFinetuningTrainingLoss(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get Finetuning Training Loss
export const cancelFinetuning = createAsyncThunk(
  "chatbot/cancelFinetuning",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.cancelFinetuning(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//edit bot name
export const updateAppName = createAsyncThunk(
  "chatbot/updateAppName",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.updateAppName(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Upload more golden qna
export const updateGoldenResponses = createAsyncThunk(
  "chatbot/updateGoldenResponses",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.updateGoldenResponses(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update prompt settings
export const updatePromptStatementSettings = createAsyncThunk(
  "chatbot/updatePromptStatementSettings",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.updatePromptStatementSettings(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// validate neo4j credentials
export const validateNeo4jCreds = createAsyncThunk(
  "chatbot/validateNeo4jCreds",
  async (data, thunkAPI) => {
    try {
      return await chatbotService.validateNeo4jCreds(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const chatbotSlice = createSlice({
  name: "chatbots",
  initialState,
  reducers: {
    resetBotState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    resetChatbot: (state) => {
      state.chatbots = [];
      state.combinations = [];
      state.selected_combinations = [];
      state.finetuning_combinations = [];
      state.finalCombinations = [];
      state.generation_combinations = [];
      state.prompts = [];
      state.defaultCombination = {};
    },
    resetCombinations: (state) => {
      state.combinations = [];
      state.finetuning_combinations = [];
      state.finalCombinations = [];
      state.generation_combinations = [];
      state.defaultCombination = {};
    },
    sortByProperty: (state, action) => {
      if (action.payload.type === "retrieval") {
        state.combinations = action.payload.sortedCombinations;
      } else if (action.payload.type === "finetuned") {
        state.finetuning_combinations = action.payload.sortedCombinations;
      } else if (action.payload.type === "generation") {
        state.generation_combinations = action.payload.sortedCombinations;
      }
    },
    sortApplicationsByCreatedAt: (state, action) => {
      state.chatbots.combinations = action.payload.sortedApplications;
    },
    setCombinationUsed: (state, action) => {
      state.combinationUsed = action.payload;
    },
    deleteCombinatioSet: (state, action) => {
      state.chatbots = action.payload.chatbots;
    },
    setDefaultCombination: (state, action) => {
      state.defaultCombination = action.payload;
    },
  },
  updateBotData: (state, action) => {
    console.log(action.payload);
    state.botData = action.payload;
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // localStorage.setItem("chatbots", JSON.stringify(action.payload));
        console.log(" action.payload", action.payload.combinations);
        // state.chatbots = action.payload;
        state.token = action.payload.token;
      })
      .addCase(createBot.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBotbyOrg.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBotbyOrg.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.gettingCombinations = false;
        // state.chatbots = action.payload;
        // Access the 'combinations' array from the action.payload
        const combinations = action.payload.combinations;
        console.log("Combinations payload: ", combinations);
        // Reverse the combinations array to show the most recent ones first
        const reversedCombinations = [...combinations].reverse();

        console.log("Combinations payload: ", reversedCombinations);
        // Update the state with the reversed combinations
        state.chatbots = {
          ...action.payload,
          combinations: reversedCombinations,
        }; // Override combinations with reversed array
      })
      .addCase(getBotbyOrg.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
        state.isError = true;
        state.gettingCombinations = false;
      })
      .addCase(makeDefault.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(makeDefault.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.gettingCombinations = false;
        // localStorage.setItem("chatbots", JSON.stringify(action.payload));
        state.chatbots = action.payload;
      })
      .addCase(makeDefault.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
        state.isError = true;
        state.gettingCombinations = false;
      })
      .addCase(getCombinations.pending, (state) => {
        state.isLoading = true;
        state.gettingCombinations = true;
      })
      .addCase(getCombinations.fulfilled, (state, action) => {
        // localStorage.setItem("combinations", JSON.stringify(action.payload));
        state.combinations = action.payload;
        state.isLoading = false;
        state.isSuccess = true;
        state.gettingCombinations = false;
      })
      .addCase(getCombinations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.gettingCombinations = false;
        state.message = action.payload;
      })
      .addCase(getgenerationCombinations.pending, (state) => {
        // state.isLoading = true;
        state.gettingCombinations = true;
      })
      .addCase(getgenerationCombinations.fulfilled, (state, action) => {
        // localStorage.setItem("generation_combinations", JSON.stringify(action.payload));
        state.generation_combinations = action.payload;
        state.isLoading = false;
        state.isSuccess = true;
        state.gettingCombinations = false;
      })
      .addCase(getgenerationCombinations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.gettingCombinations = false;
        state.message = action.payload;
      })
      .addCase(getFinetuningCombinations.pending, (state) => {
        state.isLoading = true;
        state.gettingCombinations = true;
      })
      .addCase(getFinetuningCombinations.fulfilled, (state, action) => {
        // localStorage.setItem("finetuning_combinations", JSON.stringify(action.payload));
        state.finetuning_combinations = action.payload;
        state.isLoading = false;
        state.isSuccess = true;
        state.gettingCombinations = false;
      })
      .addCase(getFinetuningCombinations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.gettingCombinations = false;
        state.message = action.payload;
      })
      .addCase(runEvals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(runEvals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.combinations = state.combinations.map((combination) =>
          combination._id["$oid"] === action.payload.combination._id["$oid"]
            ? action.payload.combination // replace with the new combination
            : combination
        );
      })
      .addCase(runEvals.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(runFinetuningEvals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(runFinetuningEvals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.finetuning_combinations = state.finetuning_combinations.map(
          (combination) =>
            combination._id["$oid"] === action.payload.combination._id["$oid"]
              ? action.payload.combination // replace with the new combination
              : combination
        );
      })
      .addCase(runFinetuningEvals.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(runGenerationEvals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(runGenerationEvals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.generation_combinations = state.generation_combinations.map(
          (combination) =>
            combination._id["$oid"] === action.payload.combination._id["$oid"]
              ? action.payload.combination // replace with the new combination
              : combination
        );
      })
      .addCase(runGenerationEvals.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(startFinetuning.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(startFinetuning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.isFinetuning = true;
      })
      .addCase(startFinetuning.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFinetuningStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFinetuningStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.finetuning_combinations = state.finetuning_combinations.map(
          (combination) =>
            combination._id["$oid"] === action.meta.arg.combination_id
              ? {
                ...combination,
                status: action.payload.status, // Update the status field
              }
              : combination
        );
      })
      .addCase(getFinetuningStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(generatePrompts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generatePrompts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.prompts = action.payload.prompts;
      })
      .addCase(generatePrompts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(initializeCombinations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initializeCombinations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        console.log("initializeCombinations", action.payload);
      })
      .addCase(initializeCombinations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(initializeEmbeddingFinetuning.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initializeEmbeddingFinetuning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(initializeEmbeddingFinetuning.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(initializeMutations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initializeMutations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.combinations = action.payload.selected_combinations;
      })
      .addCase(initializeMutations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(get_selected_combinations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_selected_combinations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selected_combinations = action.payload.selected_combinations;
      })
      .addCase(get_selected_combinations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteChatbot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteChatbot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        // state.token = action.payload.token;
      })
      .addCase(deleteChatbot.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(uploadSources.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
        // state.token = action.payload.token;
      })
      .addCase(uploadSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDataTypes.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getDataTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.dataTypes = {
          ...state.dataTypes,
          [action.payload.filename]: action.payload.datatypes,
        };
        // state.token = action.payload.token;
      })
      .addCase(getDataTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMetadataDescription.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getMetadataDescription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.metadataDescription = {
          ...state.metadataDescription,
          [action.payload.filename]: action.payload.datatypes,
        };
        // state.token = action.payload.token;
      })
      .addCase(getMetadataDescription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDefaultCombinations.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getDefaultCombinations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.defaultCombination = action.payload.defaultCombination;
        // state.token = action.payload.token;
      })
      .addCase(saveDefaultCombinations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.status;
      })
      .addCase(updateAppName.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAppName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        const { bot_id, new_name } = action.payload; // Extract bot_id and new_name from the payload

        // Find the bot in the combinations array and update its name
        const botIndex = state.chatbots?.combinations?.findIndex(
          (bot) => bot._id["$oid"] === bot_id
        );

        if (botIndex !== -1) {
          state.chatbots.combinations[botIndex].name = new_name; // Update the bot name
        }
      })
      .addCase(updateAppName.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.status;
      })
      .addCase(updateGoldenResponses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGoldenResponses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;

        const { qna_data } = action.payload;
        const { bot_id } = action.meta.arg;

        // Find the index of the bot in the combinations array
        const botIndex = state.chatbots?.combinations?.findIndex(
          (bot) => bot._id?.["$oid"] === bot_id
        );

        if (botIndex !== -1) {
          // Ensure immutability by creating a new golden_responses array
          state.chatbots.combinations[botIndex] = {
            ...state.chatbots.combinations[botIndex],
            golden_responses: [
              ...state.chatbots.combinations[botIndex].golden_responses,
              ...qna_data,
            ],
          };
        }
      })
      .addCase(updateGoldenResponses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message =
          action.payload?.status || "Error updating golden responses.";
      })
      .addCase(updatePromptStatementSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePromptStatementSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        const { bot_id } = action.meta.arg;
        const { prompt_guidelines, have_memory } = action.payload;

        // Find the index of the bot in the combinations array
        const botIndex = state.chatbots?.combinations?.findIndex(
          (bot) => bot._id?.["$oid"] === bot_id
        );

        if (botIndex !== -1) {
          // Ensure immutability by creating a new golden_responses array
          state.chatbots.combinations[botIndex] = {
            ...state.chatbots.combinations[botIndex],
            prompt_guidelines, // Update the prompt guidelines
            have_memory,
          };
        }
      })
      .addCase(updatePromptStatementSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message =
          action.payload?.status || "Error updating prompt settings.";
      })
      .addCase(saveDefaultCombinations.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(saveDefaultCombinations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;

        // Access the payload from the server response
        state.finalCombinations = action.payload.finalCombinations;

        // Access the original data that was sent with the action
        const { bot_id, default_dict } = action.meta.arg;

        // Iterate over state.chatbots.combinations and update the matching combination
        state.chatbots.combinations = state.chatbots.combinations.map(
          (combination) => {
            if (combination._id["$oid"] === bot_id) {
              console.log("Updating combination:", combination._id["$oid"]);
              return {
                ...combination,
                default_flow: default_dict, // Update default_flow with default_dict
              };
            }
            return combination;
          }
        );
      })
      .addCase(getFinetuningTrainingLoss.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.status;
      })
      .addCase(getFinetuningTrainingLoss.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getFinetuningTrainingLoss.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        console.log(action.payload);
        // state.trainingLoss = action.payload.finalCombinations;
        // state.token = action.payload.token;
      })
      .addCase(getDefaultCombinations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.status;
      })
      .addCase(cancelFinetuning.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.status;
      })
      .addCase(cancelFinetuning.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(cancelFinetuning.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.isFinetuning = false;
        console.log(action.payload);
        // state.trainingLoss = action.payload.finalCombinations;
        // state.token = action.payload.token;
      });
  },
});
export const {
  resetChatbot,
  resetBotState,
  sortByProperty,
  sortApplicationsByCreatedAt,
  setCombinationUsed,
  deleteCombinatioSet,
  setDefaultCombination,
  resetCombinations,
} = chatbotSlice.actions;
export default chatbotSlice.reducer;
