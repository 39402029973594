import colors from "../../Constants/colors";

export const textfieldLayout = {
  backgroundColor: colors.textFieldBackground, // Updated from textFieldBackground to extremeWhite
  color: colors.extremeWhite,
  mr: 2,
  borderRadius: "25px",
  borderColor: colors.tertiaryBlue,
  "& .MuiOutlinedInput-root": {
    padding: "1px 14px",
    fontSize: "0.875rem",
    borderRadius: "25px",
    "& fieldset": {
      borderColor: colors.tertiaryBlue,
    },
    "&:hover fieldset": {
      borderColor: colors.primaryBlue,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.primaryBlue,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.darkGray,
      color: colors.lightGray,
    },
  },
  input: {
    color: colors.extremeWhite,
    "&.Mui-disabled": {
      color: colors.lightGray,
    },
  },
};

export const ChatBoxMessagesStyles = {
  flex: 1, // Changed from 3 to 1 for better layout flexibility
  overflowY: "auto",
  overflowX: "hidden",
  mt: 1,
  mx: 0,
  width: "100%",
  WebkitOverflowScrolling: "touch",
  scrollbarWidth: "thin",
  scrollbarColor: `${colors.secondaryBlue} ${colors.extremeBlack}`,
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.secondaryBlue,
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: colors.extremeBlack,
    borderRadius: "10px",
  },
  padding: 2,
};

export const DeployedBotsPaperStyles = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: colors.extremeBlue,
  my: "40px",
  boxShadow: `0px 0px 70px ${colors.secondaryBlue}`,
  padding: 6,
  overflowY: "hidden", // You can remove this if you need scrolling further down the hierarchy
  height: "90vh",
  WebkitOverflowScrolling: "touch",
  scrollbarWidth: "thin",
  scrollbarColor: colors.secondaryBlue,
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.darkGray,
  },
};
